@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'libs/framework/ui-core/styles/main';
@import "../../../../libs/softapps/core/styles/main";
@import 'libs/framework/dynamic/styles/main';
@import 'libs/framework/capacitor/styles/main';

// $fa-font-path: "node_modules/@fortawesome/fontawesome-free/webfonts" !default;
@import 'node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
@import 'node_modules/@fortawesome/fontawesome-free/scss/v4-shims';
@import 'node_modules/@fortawesome/fontawesome-free/scss/regular';
@import 'node_modules/@fortawesome/fontawesome-free/scss/solid';
@import 'node_modules/@fortawesome/fontawesome-free/scss/brands';

// Baustellen auf Haus Icon
//

@media only screen and (min-width: 1024px)  {
  .soft-modal-content{
    max-width: 60vw;
  }
}
@media only screen and (min-width: 1280px)  {
  .soft-modal-content{
    max-width: 40vw;
  }
}

:root {
  --colorPrimary: #CC0605;
  --colorPrimary50: #fce1e1;
  --colorPrimary100: #FC7978;
  --colorPrimary200: #FB5251;
  --colorPrimary300: #FA2A29;
  --colorPrimary400: #F40706;
  --colorPrimary500: #CC0605;
  --colorPrimary600: #950404;
  --colorPrimary700: #5E0302;
  --colorPrimary800: #280101;
  --colorPrimary900: #260b0b;
  --colorSelected: #D4D4D4;

  --publicShellBranding: url('/assets/images/abau-logo.svg');
  --publicShellBrandingSize: contain;

  --sideMenuBranding: url('/assets/images/abau-logo.svg');
}
