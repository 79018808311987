.soft-table {
  border-collapse: collapse;
  width: 100%;
  background-color: var(--tableBackground);
  font-size: 0.875rem;

  th,
  td {
    text-align: left;
    vertical-align: var(--tableCellVerticalAlign);
    padding: var(--tableCellPadding);

    &:first-child {
      padding-left: 0.5rem;
    }

    &:last-child {
      padding-right: 0.5rem;
    }
  }

  tbody > tr {
    &:hover {
      background-color: var(--colorHover) !important;
    }
    &.selected {
      background-color: var(--colorSelected) !important;
    }
  }

  thead > tr > th {
    height: auto;
    background-color: var(--tableHeaderBackground);
    border-bottom: var(--tableHeaderSeparator);
  }

  tfoot {
    border-top: var(--tableFooterSeparator);
    background-color: var(--tableFooterBackground);
  }

  &.outline,
  &-outline {
    @apply border border-border;

    .soft-table-header,
    thead > tr > th {
      @apply border-b border-border;
    }

    .soft-table-cell,
    .soft-table-header-cell,
    th,
    td {
      border: none;
    }
  }

  &.no-border,
  &-no-border {
    .soft-table-cell,
    .soft-table-header-cell,
    th,
    td {
      border: none;
    }
  }

  &-row {
    .soft-table,
    table,
    th,
    td {
      border: none;
    }

    tr {
      border-bottom: var(--separator);
    }

    thead > tr > th {
      background-color: var(--tableHeaderBackground);
      text-align: left;
    }
  }

  &.striped,
  &-striped {
    tr {
      &:nth-child(even) {
        background-color: var(--tableRowBackgroundStriped);
      }
    }

    &-even {
      tr {
        &:nth-child(even) {
          background-color: var(--tableRowBackgroundStriped);
        }
      }
    }

    &-odd {
      thead > tr,
      .soft-table-header > tr {
        background-color: var(--tableHeaderBackground) !important;
      }

      tr {
        &:nth-child(odd) {
          background-color: var(--tableRowBackgroundStriped);
        }
      }
    }
  }

  &-cell,
  td {
    min-height: var(--tableCellMinHeight);
    height: var(--tableCellHeight);

    &-title {
      font-weight: bold;
    }

    &-list {
      list-style-type: square;
      margin: 0;
      padding: 0;
    }
  }

  &-body,
  tbody {
    padding: 0;
  }

  &-header {
    color: var(--tableHeaderColor);
  }

  &-footer {
  }
  &-footer-cell {
  }
}

.divided {
  .soft-table-body,
  .soft-table tbody {
    border: 1px solid var(--colorBorder) !important;
    font-weight: 300;
    color: var(--colorText);
  }

  .soft-table tr {
    border-bottom: 1px solid #dddddd;

    &:last-child {
      border-bottom: none;
    }
  }

  .soft-table td,th {
    padding: 0.5rem 0.75rem !important;
    border-right: 1px solid var(--colorBorder);

    &:last-child {
      border-right: none;
    }
  }

  .soft-table thead > tr > th {
    border-bottom: 1px solid var(--colorBorder);
    font-size: 0.675rem;
    text-transform: none;
  }

  .sticky th {
    background: white;
    position: sticky;
    top: 0;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  }

  .soft-table tfoot {
    border-left: 1px solid var(--colorBorder);
    border-right: 1px solid var(--colorBorder);
    border-bottom: 1px solid var(--colorBorder);
  }

  .soft-table-cell-number,
  .soft-table-cell-currency {
    text-align: right !important;
  }
}


.soft-table-2 {
  @apply w-full flex flex-col gap-4 md:table md:border;
  border-collapse: separate;
  border-spacing: 0;

  tbody {
    @apply flex flex-col w-full gap-3 rounded-sm md:table-row-group;
  }

  tr {
    @apply flex flex-col px-3 w-full shadow-md rounded md:table-row md:px-0 md:shadow-none md:rounded-none;
  }

  th {
    @apply border-b h-10 px-4 font-semibold bg-primary-400 text-primary-contrast uppercase text-sm hidden md:table-cell;
  }

  &.header-xs th {
    @apply text-xs normal-case hidden md:table-cell hover:no-underline hover:cursor-default;
  }

  &.body-s td {
    @apply text-sm;
  }

  &.body-border td {
    @apply border-r-0 border-solid border-r-border md:border-r;

    &:last-child{
      @apply border-none;
    }
  }

  tfoot {
    @apply flex flex-col w-full gap-3 rounded-sm md:table-footer-group bg-primary-50;

    td {
      @apply bg-primary-50;
    }
  }

  td {
    @apply border-b border-x-0 h-10 px-0 gap-4 flex items-center my-auto self-center justify-between w-full text-right md:px-4 md:text-left md:table-cell md:w-auto md:before:hidden;

    &.compact{
      @apply h-7 text-sm;
    }

    &::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      align-self: center;
      justify-self: center;
      white-space: nowrap;
    }

    &:last-child {
      @apply border-b-0 md:border-b;
    }
  }

  tfoot td {
    @apply border-b border-b-color md:border-b;

    &:last-child {
      @apply border-b-0 md:border-b;
    }
  }

  tr {
    &:last-child td {
      @apply md:border-b-0;
    }
  }

  tfoot tr:first-child td {
    @apply md:border-t;
  }

  tr:nth-child(even) {
    @apply bg-surface md:bg-surface-secondary;
  }

  tbody tr:nth-child(odd) {
    @apply bg-surface;
  }
}


.soft-table-2.compact {

  th {
    @apply h-7 px-3 text-xs ;
  }

  td {
    @apply text-sm;
  }

  td {
    @apply border-b border-x-0 h-7 px-0 gap-4 flex items-center my-auto self-center justify-between w-full text-right md:px-4 md:text-left md:table-cell md:w-auto md:before:hidden;
  }

  tfoot td {
    @apply border-b border-b-color md:border-b;

    &:last-child {
      @apply border-b-0 md:border-b;
    }
  }

  tr {
    &:last-child td {
      @apply md:border-b-0;
    }
  }

  tfoot tr:first-child td {
    @apply md:border-t;
  }

  tr:nth-child(even) {
    @apply bg-surface md:bg-surface-secondary;
  }

  tbody tr:nth-child(odd) {
    @apply bg-surface;
  }
}
