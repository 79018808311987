/*
 * -----------------
 * Light (default) Theme
 * -----------------
 */
:root {
  --colorBorderTableOuter: #9ea7b2;
  --colorBorderTableInner: #e7e7e7;

  /******************
  ****** Fonts ******
  ******************/
  --font: 1rem -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui,
  helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  --fontFamily: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui,
  helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;

  /******************
  ***** Colors ******
  ******************/
  --background: #f8f8f8;
  --transparent: rgba(0, 0, 0, 0);
  --colorDisabled: #d2d2d2;
  --colorReadonly: #e5e7eb;

  // TODO
  --colorHeaderBackground: #000000;
  --colorFooterBackground: #000000;

  // TODO
  --colorTopBarBackground: #000000;
  --colorTopBar: #FFFFFF;

  // TODO
  // SURFACE COLOR
  // Things like cards, the background of form controls, menus etc.
  --colorSurface: #FFFFFF;
  --colorSurfaceSecondary: #f5f5f5;
  --colorSurfaceTertiary: #ebebed;

  // TODO
  // BACKDROP COLOR
  --colorBackdrop: rgba(0, 0, 0, 0.30);

  //Primary
  --colorPrimary: #0177BF;
  --colorPrimary50: #CCE4F2;
  --colorPrimary100: #A6CFE9;
  --colorPrimary200: #80BBDF;
  --colorPrimary300: #5AA7D5;
  --colorPrimary400: #3492CC;
  --colorPrimary500: #0177BF;
  --colorPrimary600: #0165A2;
  --colorPrimary700: #015386;
  --colorPrimary800: #014169;
  --colorPrimary900: #00304C;
  --colorPrimarySelection: #E8EFF6;

  --colorPrimaryContrast: #FFFFFF;
  --colorPrimaryContrast50: #000000;
  --colorPrimaryContrast100: #000000;
  --colorPrimaryContrast200: #000000;
  --colorPrimaryContrast300: #000000;
  --colorPrimaryContrast400: #FFFFFF;
  --colorPrimaryContrast500: #FFFFFF;
  --colorPrimaryContrast600: #FFFFFF;
  --colorPrimaryContrast700: #FFFFFF;
  --colorPrimaryContrast800: #FFFFFF;
  --colorPrimaryContrast900: #FFFFFF;

  //Secondary
  --colorSecondary: #b7cae0;
  --colorSecondary50: #fbfcfd;
  --colorSecondary100: #f8fafc;
  --colorSecondary200: #edf2f7;
  --colorSecondary300: #e2eaf3;
  --colorSecondary400: #cddae9;
  --colorSecondary500: #b7cae0;
  --colorSecondary600: #a5b6ca;
  --colorSecondary700: #8998a8;
  --colorSecondary800: #6e7986;
  --colorSecondary900: #5a636e;
  --colorSecondaryContrast: #000000;
  --colorSecondaryContrast50:#000000;
  --colorSecondaryContrast100: #000000;
  --colorSecondaryContrast200: #000000;
  --colorSecondaryContrast300: #000000;
  --colorSecondaryContrast400: #000000;
  --colorSecondaryContrast500: #000000;
  --colorSecondaryContrast600: #FFFFFF;
  --colorSecondaryContrast700: #FFFFFF;
  --colorSecondaryContrast800: #FFFFFF;
  --colorSecondaryContrast900: #FFFFFF;

  //Tertiary
  --colorTertiary: #7164bd;
  --colorTertiary50: #f8f7fc;
  --colorTertiary100: #f1f0f8;
  --colorTertiary200: #dcd8ef;
  --colorTertiary300: #c6c1e5;
  --colorTertiary400: #9c93d1;
  --colorTertiary500: #7164bd;
  --colorTertiary600: #665aaa;
  --colorTertiary700: #554b8e;
  --colorTertiary800: #443c71;
  --colorTertiary900: #37315d;
  --colorTertiaryContrast: #000000;
  --colorTertiaryContrast50: #000000;
  --colorTertiaryContrast100: #000000;
  --colorTertiaryContrast200: #000000;
  --colorTertiaryContrast300: #FFFFFF;
  --colorTertiaryContrast400: #FFFFFF;
  --colorTertiaryContrast500: #FFFFFF;
  --colorTertiaryContrast600: #FFFFFF;
  --colorTertiaryContrast700: #FFFFFF;
  --colorTertiaryContrast800: #FFFFFF;
  --colorTertiaryContrast900: #FFFFFF;

  //Accent
  --colorAccent: #32DBBE;
  --colorAccent50: #CEF6F0;
  --colorAccent100: #BDF3EA;
  --colorAccent200: #9AEDDF;
  --colorAccent300: #77E7D4;
  --colorAccent400: #55E1C9;
  --colorAccent500: #32DBBE;
  --colorAccent600: #20B59B;
  --colorAccent700: #178573;
  --colorAccent800: #0F564A;
  --colorAccent900: #072621;
  --colorAccentSelection: #c5e8e3;

  --colorAccentContrast: #000000;
  --colorAccentContrast50: #000000;
  --colorAccentContrast100: #000000;
  --colorAccentContrast200: #000000;
  --colorAccentContrast300: #000000;
  --colorAccentContrast400: #000000;
  --colorAccentContrast500: #000000;
  --colorAccentContrast600: #000000;
  --colorAccentContrast700: #FFFFFF;
  --colorAccentContrast800: #FFFFFF;
  --colorAccentContrast900: #FFFFFF;

  //Danger
  --colorDanger: #f43f5a;
  --colorDanger50: #fff1f2;
  --colorDanger100: #ffe4e6;
  --colorDanger200: #fecdd3;
  --colorDanger300: #fda4a7;
  --colorDanger400: #fb7186;
  --colorDanger500: #f43f5a;
  --colorDanger600: #e11d51;
  --colorDanger700: #be123a;
  --colorDanger800: #9f1239;
  --colorDanger900: #881337;
  --colorDangerContrast: #FFFFFF;
  --colorDangerContrast50: #000000;
  --colorDangerContrast100: #000000;
  --colorDangerContrast200: #000000;
  --colorDangerContrast300: #FFFFFF;
  --colorDangerContrast400: #FFFFFF;
  --colorDangerContrast500: #FFFFFF;
  --colorDangerContrast600: #FFFFFF;
  --colorDangerContrast700: #FFFFFF;
  --colorDangerContrast800: #FFFFFF;
  --colorDangerContrast900: #FFFFFF;

  //Promotion
  --colorPromotion: #f43f5a;
  --colorPromotion50: #fff1f2;
  --colorPromotion100: #ffe4e6;
  --colorPromotion200: #fecdd3;
  --colorPromotion300: #fda4a7;
  --colorPromotion400: #fb7186;
  --colorPromotion500: #f43f5a;
  --colorPromotion600: #e11d51;
  --colorPromotion700: #be123a;
  --colorPromotion800: #9f1239;
  --colorPromotion900: #881337;
  --colorPromotionContrast: #FFFFFF;
  --colorPromotionContrast50: #000000;
  --colorPromotionContrast100: #000000;
  --colorPromotionContrast200: #000000;
  --colorPromotionContrast300: #FFFFFF;
  --colorPromotionContrast400: #FFFFFF;
  --colorPromotionContrast500: #FFFFFF;
  --colorPromotionContrast600: #FFFFFF;
  --colorPromotionContrast700: #FFFFFF;
  --colorPromotionContrast800: #FFFFFF;
  --colorPromotionContrast900: #FFFFFF;

  //Error
  --colorError: #f43f5a;
  --colorError50: #fff1f2;
  --colorError100: #ffe4e6;
  --colorError200: #fecdd3;
  --colorError300: #fda4a7;
  --colorError400: #fb7186;
  --colorError500: #f43f5a;
  --colorError600: #e11d51;
  --colorError700: #be123a;
  --colorError800: #9f1239;
  --colorError900: #881337;
  --colorErrorContrast: #FFFFFF;
  --colorErrorContrast50: #000000;
  --colorErrorContrast100: #000000;
  --colorErrorContrast200: #000000;
  --colorErrorContrast300: #FFFFFF;
  --colorErrorContrast400: #FFFFFF;
  --colorErrorContrast500: #FFFFFF;
  --colorErrorContrast600: #FFFFFF;
  --colorErrorContrast700: #FFFFFF;
  --colorErrorContrast800: #FFFFFF;
  --colorErrorContrast900: #FFFFFF;

  // WARNING COLOR
  --colorWarning100: #ffedd7;
  --colorWarning200: #fce3c6;
  --colorWarning300: #ffd096;
  --colorWarning400: #fcc27e;
  --colorWarning500: #ffae49;
  --colorWarning600: #f6a036;
  --colorWarning700: #d77e11;
  --colorWarning800: #b46300;
  --colorWarning900: #884c00;
  --colorWarningContrast100: #000000;
  --colorWarningContrast200: #000000;
  --colorWarningContrast300: #000000;
  --colorWarningContrast400: #FFFFFF;
  --colorWarningContrast500: #FFFFFF;
  --colorWarningContrast600: #FFFFFF;
  --colorWarningContrast700: #FFFFFF;
  --colorWarningContrast800: #FFFFFF;
  --colorWarningContrast900: #FFFFFF;

  // INFO COLOR
  --colorInfo100: #d9edf6;
  --colorInfo200: #d3edf8;
  --colorInfo300: #a4c8e7;
  --colorInfo400: #93c2e0;
  --colorInfo500: #62a6d5;
  --colorInfo600: #5cbbff;
  --colorInfo700: #3591d2;
  --colorInfo800: #1f74b2;
  --colorInfo900: #0f4669;
  --colorInfoContrast100: #000000;
  --colorInfoContrast200: #000000;
  --colorInfoContrast300: #000000;
  --colorInfoContrast400: #000000;
  --colorInfoContrast500: #000000;
  --colorInfoContrast600: #FFFFFF;
  --colorInfoContrast700: #FFFFFF;
  --colorInfoContrast800: #FFFFFF;
  --colorInfoContrast900: #FFFFFF;

  // SUCCESS COLOR
  --colorSuccess100: #d6ffdf;
  --colorSuccess200: #c6ffd4;
  --colorSuccess300: #a9f3ba;
  --colorSuccess400: #61d97a;
  --colorSuccess500: #50c969;
  --colorSuccess600: #39b956;
  --colorSuccess700: #1a9b36;
  --colorSuccess800: #0c8326;
  --colorSuccess900: #036719;
  --colorSuccessContrast100: #000000;
  --colorSuccessContrast200: #000000;
  --colorSuccessContrast300: #000000;
  --colorSuccessContrast400: #000000;
  --colorSuccessContrast500: #FFFFFF;
  --colorSuccessContrast600: #FFFFFF;
  --colorSuccessContrast700: #FFFFFF;
  --colorSuccessContrast800: #FFFFFF;
  --colorSuccessContrast900: #FFFFFF;

  --colorBorder: #e5e7eb;
  --colorBorderLight: #e9ecf2;
  --colorTopContainerBackground: var(--colorSurface);

  //Messages
  --colorError: rgb(164, 38, 44);
  --colorErrorBackground: rgba(245, 135, 145, 0.2);
  --colorDangerBackground: rgba(245, 135, 145, 0.2);
  --colorWarning: rgb(175, 103, 0);
  --colorWarningBackground: rgba(255, 200, 10, 0.2);
  --colorSuccess: rgb(16, 124, 16);
  --colorSuccessBackground: rgba(95, 210, 85, 0.2);
  --colorInfo: rgb(106, 117, 120);
  --colorInfoBackground: rgb(243, 242, 241);

  /******************
  *** Typography ***
  ******************/
  --colorText: rgba(0, 0, 0, 0.9);
  --colorTextLight: rgba(0, 0, 0, 0.5);
  --colorTextLighter: rgba(0, 0, 0, 0.2);

  --colorLink: rgba(119, 140, 174, 0.87);
  --colorTitle: var(--colorText);
  --colorSubtitle: var(--colorTextLight);
  --colorOverline: var(--colorAccent700);

  --colorBalancePositive: rgba(0, 0, 0, 0.85);
  --colorBalanceNegative: rgba(255, 0, 0, 0.85);

  /******************
  **** Components ****
  ******************/

  --colorSelected: #CAEBFF;
  --colorHover: rgba(0, 0, 0, 0.075);

  //Input
  --inputBackground: var(--colorSurface);
  --inputText: var(--colorText);
  --inputPlaceholder: #999a9b;
  --inputBorder: 1px solid #b7cae0;
  --inputBorderRadius: 0;

  //Panel
  --panelBackground: var(--colorSurface);
  --panelText: var(--colorText);
  --panelBorderColor: var(--colorBorder);
  --panelBorder: none;
  --panelBorderRadius: 0;

  // Card
  --cardBoxShadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --cardBoxShadowHard: 0 1px 3px 0 rgb(0 0 0 / 0.5), 0 1px 2px -1px rgb(0 0 0 / 0.5);

  //Chip
  --chipColor: rgba(0, 0, 3, 0.08);
  --chipColorText: var(--colorText);
  --chipBorderRadius: 24px;

  //DatePicker
  --datePickerColorLastMonth: #b4b4b4;
  --datePickerColorToday: #d4d4d4;

  //FileInput
  --fileInputBorder: 4.5px dashed var(--colorSecondary);

  //LoadingBar
  --loadingBarColor: linear-gradient(
      to right,
      #b7cae000,
      #b7cae088,
      #b7cae0aa,
      #b7cae0dd,
      #b7cae0aa,
      #b7cae088,
      #b7cae000
  );

  //Scroll
  --scrollBackground: var(--colorSurfaceTertiary);
  --scrollIndicator: var(--colorPrimary200);

  //Skeleton
  --skeletonColor: rgba(255, 255, 255, 0.4);
  --skeletonColorBackground: #dddddd;

  //Spinner
  --spinnerColor: #171717;
  --spinnerBackground: #d2d2d2;

  //Tab
  --tabIndicator: (--colorPrimary);

  // ToolTip
  --toolTipBorder: 1px solid #b7cae0;

  //Table
  --tableBorder: 1px solid #b7cae0;
  --tableBackground: var(--panelBackground);

  --tableHeaderSeparator: none;
  --tableHeaderBackground: var(--panelBackground);
  --tableHeaderColor: var(--colorText);

  --tableRowBackground: var(--panelBackground) ;
  --tableRowBackgroundStriped: #ebebeb;
  --tableRowColor: var(--colorText);
  --tableRowSeparator: none;

  --tableFooterBackground: rgba(0, 0, 0, 0.03);
  --tableFooterColor: var(--colorText);
  --tableFooterSeparator: 1px solid rgba(0, 0, 0, 0.15);

  --tableCellHeight: auto;
  --tableCellMinHeight: 18px;
  --tableCellPadding: 0.25rem 0.75rem;
  --tableCellVerticalAlign: middle;
  --tableCellBorder: none;

  //Progress
  --progressColor: rgba(112, 150, 193, 0.2);

  //Modal
  // TODO: Replace with colorBackdrop
  --modalOverlayBackground: rgba(0, 0, 0, 0.4);

  /******************
  ****** Misc ******
  ******************/
  --separator: 1px solid var(--colorBorder);

  // old
  --sectionHeaderColor: rgba(1, 1, 7, 0.33);
  --sectionHeaderBorderColor: rgba(0, 0, 0, 0.15);
}
